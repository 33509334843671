import { useMemo } from 'react';

import { useApplication } from '@common/application';

import { format } from './format';
import { getFormatter } from './formatters';
import { Address, MessageContext } from './types';

export const useFormatter = () => {
  const { locale } = useApplication();
  const { typeHandlers } = getFormatter(locale);

  return useMemo(
    () => ({
      format: (message: string, context: MessageContext): string => format(message, locale, context),
      date: {
        short: (value: Date | number | string): string => typeHandlers.date(value, 'short', locale),
        medium: (value: Date | number | string): string => typeHandlers.date(value, 'medium', locale),
        long: (value: Date | number | string): string => typeHandlers.date(value, 'long', locale),
        dayOfWeekShort: (value: Date | number | string): string => typeHandlers.date(value, 'dayOfWeekShort', locale),
        dayOfWeekLong: (value: Date | number | string): string => typeHandlers.date(value, 'dayOfWeekLong', locale),
        dayOfMonthNumeric: (value: Date | number | string): string =>
          typeHandlers.date(value, 'dayOfMonthNumeric', locale),
        monthLong: (value: Date | number | string): string => typeHandlers.date(value, 'monthLong', locale),
        monthShort: (value: Date | number | string): string => typeHandlers.date(value, 'monthShort', locale),
        hourLong: (value: Date | number | string): string => typeHandlers.date(value, 'hourLong', locale),
        hourShort: (value: Date | number | string): string => typeHandlers.date(value, 'hourShort', locale),
        year: (value: Date | number | string): string => typeHandlers.date(value, 'year', locale),
        period: (value: Date | number | string): string =>
          [typeHandlers.date(value, 'monthLong', locale), typeHandlers.date(value, 'year', locale)].join(' '),
      },
      currency: {
        euro: (value: number | string): string => typeHandlers.number(value, '::currency/EUR', locale),
        euroHighPrecision: (value: number | string): string => typeHandlers.number(value, 'euroHighPrecision', locale),
        euroFiveDecimals: (value: number): string => typeHandlers.number(value, 'euroFiveDecimals', locale),
        euroNoFractionDigits: (value: number | string): string =>
          typeHandlers.number(value, 'euroNoFractionDigits', locale),
      },
      number: {
        noFractionDigits: (value: number) => typeHandlers.number(value, 'noFractionDigits', locale),
        oneFractionDigit: (value: number) => typeHandlers.number(value, 'oneFractionDigit', locale),
        twoFractionDigits: (value: number) => typeHandlers.number(value, 'twoFractionDigits', locale),
      },
      unit: {
        gas: (value: number): string => typeHandlers.unit(value, 'gas', locale),
        electricity: (value: number): string => typeHandlers.unit(value, 'electricity', locale),
        redelivery: (value: number): string => typeHandlers.unit(value, 'redelivery', locale),
        warmth: (value: number): string => typeHandlers.unit(value, 'warmth', locale),
        tapWater: (value: number): string => typeHandlers.unit(value, 'tapWater', locale),
        produced: (value: number): string => typeHandlers.unit(value, 'produced', locale),
        cooling: (value: number): string => typeHandlers.unit(value, 'cooling', locale),
      },
      address: {
        streetAddress: (value: Address): string => typeHandlers.address(value, 'streetAddress', locale),
        postalCodeCity: (value: Address): string => typeHandlers.address(value, 'postalCodeCity', locale),
        medium: (value: Address): string => typeHandlers.address(value, 'medium', locale),
        long: (value: Address): string => typeHandlers.address(value, 'long', locale),
      },
    }),
    [locale, typeHandlers],
  );
};

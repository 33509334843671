import { FC, useEffect, useState } from 'react';

import { useRouter } from '@dxp-next';

import { ReturnPathContext } from '../hooks/handle-authentication';

const ReturnPathProvider: FC<{
  children: React.ReactNode;
  ignorePaths?: string[];
}> = ({ children, ignorePaths = [] }) => {
  const { activePath } = useRouter();
  const [returnPath, setReturnPath] = useState('');

  useEffect(() => {
    if (!ignorePaths.find(path => path === activePath)) {
      setReturnPath(activePath);
    }
  }, [activePath, ignorePaths]);

  return <ReturnPathContext.Provider value={returnPath}>{children}</ReturnPathContext.Provider>;
};

export default ReturnPathProvider;

/**
 * Capacitor preferences API keys
 *
 * For more info, see: https://capacitorjs.com/docs/guides/storage#capacitor-preferences-api
 */
export enum AppPreferences {
  /**
   * When user doesn`t have energy profile we show them the energy profile flow when the app starts.
   * User can close the flow without completing it, to prevent showing the flow again
   * we use the ENERGY_PROFILE_REQUESTED key`.
   */
  ENERGY_PROFILE_REQUESTED = 'energyProfileRequested',
  PARTIAL_MANDATE_MODE = 'partialMandateMode',
  LAST_LOGIN = 'last-login',
  TOKEN_RESPONSE_KEY = 'token-response',
  ENERGY_PROFILE_STORAGE_KEY = 'energyProfileFlow',
  FIRST_TIME_OPEN = 'firstTimeOpen',
  SMARTCHARGING_ONBOARDING_BANNER_CLOSED = 'smartchargingOnboardingBannerClosed',
  SMARTCHARGING_COMPATIBILITY_SUCCESS = 'smartchargingCompatibilitySuccess',
}

import { FC, useEffect, useState } from 'react';

import { useApplication } from '@common/application';
import env from '@common/env';
import { useCustomerGetCustomerProfile } from '@dc/hooks';
import { Script, useRouter } from '@dxp-next';
import { useContent } from '@sitecore/common';
import { LayoutServiceExperimentInfo } from '@sitecore/common/types';
import { ClientDataLayer, InitialDataLayer, getDataLayerEnvironment, pushToDataLayer } from '@tracking';

const GTM_ID = env('GTM_ID');
const GTM_URL = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`;
// The noscript iframe is disabled (at least for now), since we can't login when JS is disabled anyway
// const GTM_NS_URL = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;

const initialDataLayer: InitialDataLayer = {
  platformVersion: 4,
};

export interface Props {
  customerId?: string;
  accountId?: number | null;
  experimentInfo?: LayoutServiceExperimentInfo;
}

const experimentInfoAsObject = (experimentInfo: LayoutServiceExperimentInfo) => {
  return {
    experimentId: experimentInfo.id,
    experimentName: experimentInfo.name,
    experimentVariant: experimentInfo.variant,
    experimentPath: experimentInfo.path,
  };
};

const GTMTag: FC<React.PropsWithChildren<Props>> = ({ customerId, accountId }: Props) => {
  const { data: profile } = useCustomerGetCustomerProfile();

  const experimentInfo = useContent().context?.experimentInfo;
  const getExperimentInfo = () => {
    if (experimentInfo) {
      return experimentInfoAsObject(experimentInfo);
    }
    return null;
  };

  const handleRouteChange = () => {
    if (experimentInfo) {
      pushToDataLayer({
        event: 'view_experiment',
        ...getExperimentInfo(),
      });
    }
  };

  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  const [initialEvent, setInitialEvent] = useState<ClientDataLayer | null>(null);
  const [start] = useState(new Date().getTime());
  const { searchParams } = useApplication();

  useEffect(() => {
    if (customerId && profile?.customerType) {
      const event = {
        clientId: customerId,
        customerType: profile.customerType,
      };

      if (profile?.accounts && accountId) {
        const foundAccount = profile.accounts.find(account => account.accountId === accountId);
        if (foundAccount) {
          Object.assign(event, {
            currentAccount: {
              accountId,
              customerProfileType: foundAccount.customerProfileType,
              productTypes: foundAccount.productTypes,
              hasRedelivery: foundAccount.hasRedelivery,
            },
          });
        }
      }

      // the initial event should only get set when its first loaded or when the accountId changes
      if (!initialEvent || accountId !== initialEvent?.currentAccount?.accountId) {
        setInitialEvent(event);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, accountId, customerId]);

  useEffect(() => {
    if (initialEvent) {
      window.dataLayer.push({
        ...initialEvent,
        environment: getDataLayerEnvironment(),
      });
    }
  }, [initialEvent]);

  if (!GTM_ID || searchParams.get('DisableGTM') === '1') return null;

  const content = [
    'window.dataLayer = window.dataLayer || []',
    `window.dataLayer.push(${JSON.stringify(
      Object.assign(
        {
          clientId: customerId,
          environment: getDataLayerEnvironment(),
        },
        initialDataLayer,
      ),
    )})`,
    `window.dataLayer.push(${JSON.stringify({ 'gtm.start': start, event: 'gtm.js' })})`,
    `${
      experimentInfo
        ? `window.dataLayer.push(${JSON.stringify(
            Object.assign({
              event: 'view_experiment',
              ...getExperimentInfo(),
            }),
          )})`
        : ''
    }`,
  ].join(';');

  return (
    <>
      <Script strategy="afterInteractive" id="gtm-start">
        {content}
      </Script>
      <Script strategy="afterInteractive" src={GTM_URL} id="gtm-script" />
    </>
  );
};

export default GTMTag;

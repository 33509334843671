import env from '@common/env';

type Headers = Record<string, string>;

interface Config {
  HOST: string;
  BASEPATH: string;
  VERSION: string;
  WITH_CREDENTIALS: boolean;
  TOKEN: string | undefined;
  HEADERS: Headers;
}

export const OpenAPI: Config = {
  HOST: env('DC_HOST'),
  BASEPATH: env('DC_BASEPATH') ?? '',
  VERSION: '1.0.0',
  WITH_CREDENTIALS: false,
  TOKEN: undefined, // Is set in DC provider
  HEADERS: {
    apiKey: env('DC_API_KEY') ?? 'ERROR_NO_DC_API_KEY_FOUND',
  },
};

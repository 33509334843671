import { FC } from 'react';

import useDC from '@dc/useDC';

import GTMTag from './GTMTag';

const GTM: FC<React.PropsWithChildren> = () => {
  const { customerId: customerIdNumber, accountId } = useDC();
  const customerId = customerIdNumber ? String(customerIdNumber) : undefined;

  return <GTMTag customerId={customerId} accountId={accountId} />;
};

export default GTM;

import { InAppBrowser, InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser';
import { isPlatform } from '@ionic/core';
import { Subscription } from 'rxjs';

import env from '@common/env';

// Todo write unit tests for AuthBrowser

export class Browser {
  public inAppBrowser: InAppBrowserObject | null = null;
  private subscription: Subscription | null = null;

  private navigateToPath(url: string) {
    const path = url.replace(`${env('CAPACITOR_LOCAL_SCHEMA')}:/`, '');
    document.dispatchEvent(new CustomEvent('deepLink', { detail: path }));
    this.closeWindow();
  }

  private createInAppBrowser(targetUrl: string, target = '_blank'): InAppBrowserObject {
    return InAppBrowser.create(targetUrl, target, {
      location: 'no',
      footer: 'no',
      usewkwebview: 'yes',
      clearsessioncache: 'yes',
      clearcache: 'yes',
      toolbar: 'no',
    });
  }

  private handlePlatformSpecificRouting(redirectOnExit = false): void {
    if (!this.inAppBrowser) {
      throw new Error('inAppBrowser must be set, use createInAppBrowser first');
    }
    if (!env('CAPACITOR_LOCAL_SCHEMA')) {
      throw new Error('env variable CAPACITOR_LOCAL_SCHEMA is not set');
    }
    if (isPlatform('android')) {
      this.subscription = this.inAppBrowser.on('customscheme').subscribe(({ url }) => {
        this.navigateToPath(url);
      });

      if (redirectOnExit) {
         // We redirect to '/' (login) so that the user won't be stuck
        // on the loader when pushing the back button on Android.
        this.subscription = this.inAppBrowser.on('exit').subscribe(() => {
          this.navigateToPath('/')
        });
      }
    }
    if (isPlatform('ios')) {
      this.subscription = this.inAppBrowser.on('loadstart').subscribe(({ url }) => {
        if (url.split(':')[0] === env('CAPACITOR_LOCAL_SCHEMA')) {
          this.navigateToPath(url);
        }
      });
    }
  }

  public closeWindow() {
    if (this.inAppBrowser) {
      this.inAppBrowser.close();
      this.subscription?.unsubscribe();
    }
  }

  public showWindow(targetUrl: string, target = '_blank', redirectOnExit = false) {
    if (!isPlatform('hybrid')) {
      window.open(targetUrl, '_self');
      return;
    }

    this.inAppBrowser = this.createInAppBrowser(targetUrl, target);

    this.handlePlatformSpecificRouting(redirectOnExit);
    this.inAppBrowser.show();
    return undefined;
  }
}

export default new Browser();

import { Requestor } from '@openid/appauth';
import { XhrSettings } from 'ionic-appauth/lib/cordova';

export class AuthRequestor extends Requestor {
  public xhr(settings: XhrSettings) {
    if (!settings.method) {
      settings.method = 'GET';
    }

    // Todo figure out request startegy fetch vs @capacitor http, cors?

    switch (settings.method) {
      case 'GET':
        return fetch(settings.url, {
          method: 'GET',
          referrer: window.location.origin,
          referrerPolicy: 'strict-origin-when-cross-origin',
          mode: 'cors',
          credentials: 'omit',
          headers: { ...settings.headers },
        }).then(value => value.json());
      case 'POST':
        return fetch(settings.url, {
          method: 'POST',
          referrer: window.location.origin,
          body: settings.data,
          referrerPolicy: 'strict-origin-when-cross-origin',
          mode: 'cors',
          credentials: 'omit',
          headers: { ...settings.headers },
        }).then(value => value.json());
      case 'PUT':
        return fetch(settings.url, {
          method: 'PUT',
          referrer: window.location.origin,
          body: settings.data,
          referrerPolicy: 'strict-origin-when-cross-origin',
          mode: 'cors',
          credentials: 'omit',
          headers: { ...settings.headers },
        }).then(value => value.json());
      case 'DELETE':
        return fetch(settings.url, {
          method: 'DELETE',
          referrer: window.location.origin,
          referrerPolicy: 'strict-origin-when-cross-origin',
          mode: 'cors',
          credentials: 'omit',
          headers: { ...settings.headers },
        }).then(value => value.json());
    }
  }
}

import { useContext, useEffect } from 'react';
import { createContext } from 'react';

import { Preferences } from '@capacitor/preferences';
import { AuthActions, IAuthAction } from 'ionic-appauth';
import { filter, take } from 'rxjs/operators';

import logger from '@common/log';
import { AppPreferences } from '@common/preferences';

import { getAuthService } from '../service';

export const ReturnPathContext = createContext('');

const { LAST_LOGIN } = AppPreferences;

export function useProcessLogin(loginSuccessRoute = '/', loginFailedRoute = '/') {
  const returnPath = useContext(ReturnPathContext);

  useEffect(() => {
    async function handleLoginSuccess() {
      await Preferences.set({
        key: LAST_LOGIN,
        value: `${new Date().getTime()}`,
      });
      if (returnPath) {
        window.location.assign(returnPath);
      } else {
        window.location.assign(loginSuccessRoute);
      }
    }

    // Wait for auth service to be initialized
    getAuthService()
      .initComplete$.pipe(
        filter(complete => complete),
        take(1),
      )
      .subscribe(action => {
        const url = getAuthService().authConfig.redirect_url + window.location.search;
        getAuthService().authorizationCallback(url);
      });

    getAuthService()
      .events$.pipe(
        filter(
          (action: IAuthAction) =>
            action.action === AuthActions.SignInSuccess || action.action === AuthActions.SignInFailed,
        ),
        take(1),
      )
      .subscribe(action => {
        if (action.action === AuthActions.SignInSuccess) {
          handleLoginSuccess();
        }

        if (action.action === AuthActions.SignInFailed) {
          logger.error('jreh5T', `Login error: ${AuthActions.SignInFailed}`);
          window.location.assign(loginFailedRoute);
        }
      });
    // eslint-disable-next-line
  }, []);
}

export function useProcessLogout(logoutSuccesRoute = '/', logoutFailedRoute = '/') {
  useEffect(() => {
    getAuthService().endSessionCallback();

    const subscription = getAuthService().events$.subscribe(action => {
      if (action.action === AuthActions.SignOutSuccess) {
        window.location.assign(logoutSuccesRoute);
      }

      if (action.action === AuthActions.SignOutFailed) {
        logger.error('jreh5T', `Logout error: ${AuthActions.SignOutFailed}`);
        window.location.assign(logoutFailedRoute);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);
}

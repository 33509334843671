import { createContext, ReactNode, useContext, useState } from 'react';

import { useDebounce } from 'react-use';

import { styled } from '@sparky/stitches';

import Loader from './Loader';

interface LoadingContextInterface {
  setLoading: (loading: boolean, text?: string) => void;
}

const LoadingContext = createContext<LoadingContextInterface | null>(null);

export function useGlobalLoader() {
  return useContext(LoadingContext);
}

interface Props {
  children: ReactNode;
  initiallyLoading: boolean;
}

const Wrapper = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '$backgroundPrimary',
  zIndex: 1,
});

export const GlobalLoader = ({ children, initiallyLoading = false }: Props) => {
  const [loading, setLoading] = useState<boolean>(initiallyLoading);
  const [loadingState, setLoadingState] = useState<boolean>(initiallyLoading);
  const [text, setText] = useState<string>('');

  useDebounce(
    () => {
      setLoading(loadingState);
    },
    500,
    [loadingState],
  );

  return (
    <LoadingContext.Provider
      value={{
        setLoading: (isLoading, text) => {
          setText(text ?? '');
          setLoadingState(isLoading);
        },
      }}>
      {children}
      {loading && (
        <Wrapper>
          <Loader text={text} />
        </Wrapper>
      )}
    </LoadingContext.Provider>
  );
};

import { useEffect, useState } from 'react';

import { TokenResponse } from '@openid/appauth';
import { switchMap, filter, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';

import { getAuthService } from '../service';

export interface TokenData {
  accessToken: string;
  customerId: string;
}

interface UserInfo {
  sub: string;
  customerId: string;
}

export function useAuthToken() {
  const [tokenData, setTokenData] = useState<TokenData | undefined | null>();

  useEffect(() => {
    const authService = getAuthService();

    function updateTokenState(token: TokenResponse, userInfo: UserInfo) {
      if (token && userInfo) {
        setTokenData({
          accessToken: token?.accessToken,
          customerId: userInfo?.customerId,
        });
      } else {
        setTokenData(null);
      }
    }

    function handleTokenUserData(token: TokenResponse, userInfo: UserInfo) {
      if (!token) {
        setTokenData(null);
        return;
      }
      updateTokenState(token, userInfo);
    }

    const tokenSubscription = authService.initComplete$
      .pipe(
        filter(initComplete => initComplete === true),
        switchMap(() => {
          return authService.token$.pipe(distinctUntilChanged((prev, next) => prev?.accessToken === next?.accessToken));
        }),
      )
      .pipe(withLatestFrom(authService.user$.pipe(distinctUntilChanged((prev, next) => prev?.sub === next?.sub))))
      .subscribe(([token, userInfo]: [TokenResponse, UserInfo]) => {
        handleTokenUserData(token, userInfo);
      });

    const userSubscription = authService.initComplete$
      .pipe(
        filter(initComplete => initComplete === true),
        switchMap(() => {
          return authService.user$.pipe(distinctUntilChanged((prev, next) => prev?.sub === next?.sub));
        }),
      )
      .pipe(
        withLatestFrom(
          authService.token$.pipe(distinctUntilChanged((prev, next) => prev?.accessToken === next?.accessToken)),
        ),
      )
      .subscribe(([userInfo, token]: [UserInfo, TokenResponse]) => {
        handleTokenUserData(token, userInfo);
      });

    return () => {
      tokenSubscription.unsubscribe();
      userSubscription.unsubscribe();
    };
  }, []);

  return tokenData;
}

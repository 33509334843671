import { FC } from 'react';

import { styled } from '@sparky/stitches';
import { globalStitches } from '@sparky/stitches';

export const FullHeight = styled('div', {
  height: '100vh',
  display: 'flex',
  gap: '0',
  flexWrap: 'nowrap',
  flexDirection: 'column',
});

const nativeGlobalStyles = globalStitches({
  'html, body': {
    backgroundColor: '$backgroundSecondary !important',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  'button, ::before': {
    outlineColor: 'none !important', // For native apps disable focus outline color
    outlineStyle: 'none !important',
    outlineWidth: 'none !important',
  },
});

type Props = {
  children: React.ReactNode;
};

const NativeAppWrapper: FC<Props> = ({ children }) => {
  nativeGlobalStyles();

  return <FullHeight>{children}</FullHeight>;
};

export default NativeAppWrapper;

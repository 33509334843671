import { Preferences } from '@capacitor/preferences';
import {
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import { IAuthConfig } from 'ionic-appauth';

import { AppPreferences } from '@common/preferences';

import { AuthRequestor } from './AuthRequestor';

const { TOKEN_RESPONSE_KEY } = AppPreferences;

export async function refreshToken(
  configuration: Promise<AuthorizationServiceConfiguration>,
  authConfig: IAuthConfig,
): Promise<boolean> {
  const authRequestor = new AuthRequestor();
  const token = await Preferences.get({
    key: TOKEN_RESPONSE_KEY,
  });
  if (token && token.value && authRequestor) {
    const tokenResponse = new TokenResponse(JSON.parse(token.value));

    if (tokenResponse.isValid(0)) {
      return true;
    }
    const tokenHandler = new BaseTokenRequestHandler(authRequestor);
    const requestJSON = {
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: tokenResponse.refreshToken,
      redirect_uri: authConfig.redirect_url,
      client_id: authConfig.client_id,
    };

    const config = await configuration;
    let newToken: TokenResponse | undefined = undefined;
    try {
      newToken = await tokenHandler.performTokenRequest(config, new TokenRequest(requestJSON));
    } catch (error) {
      //eslint-disable-next-line
      console.error(error);
      return false;
    }

    if (newToken && newToken.isValid(0)) {
      await Preferences.set({
        key: TOKEN_RESPONSE_KEY,
        value: JSON.stringify(newToken.toJson()),
      });
      return true;
    }
  }

  return false;
}

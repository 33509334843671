/* eslint-disable @typescript-eslint/no-explicit-any -- we need to use any for our generics */
import { useMemo } from 'react';

import useSWR, { SWRConfiguration } from 'swr';
import { useDeepCompareMemo } from 'use-deep-compare';

import { handleDownload as handleBlobDownload } from '@common/download';
import env from '@common/env';
import logger from '@common/log';
import { revalidateIfCacheIsExpired } from '@common/swr';
import { UnwrapReturnType } from '@common/ts/PromiseArg';

import { ApiError } from './ApiError';
import { OpenAPI } from './OpenAPI';
import {
  ApiRequestConfig,
  ApiRequestOptions,
  ApiResult,
  DCParams,
  DCServiceCall,
  InjectionInfo,
  UseDCHook,
} from './types';
import useDC from '../useDC';

const HEADER_CONTENT_TYPE = 'Content-Type';
const HEADER_CONTENT_DISPOSITION = 'Content-Disposition';

export function getUrl(options: ApiRequestOptions, config?: ApiRequestConfig): URL {
  let path = OpenAPI.BASEPATH + options.path.replace(/[:]/g, '_');

  // Paths that for instance contain base64 encoded data should not be lowercased
  //
  // Unfortunately, little is known about why lowercasing was introduced and what the implications are of removing lowercasing
  // is nearly impossible to determine. To make sure we don't introduce any regression and still allow paths not to be lowercased
  // we introduce a new option `preservePathCase` that can be set to true to prevent lowercasing.
  //
  // For reference: https://github.com/eneco-online/eneco-dxp-frontend/commit/58fef08b60fa956f5695e1a975052019dc93ee8f
  if (!config?.preservePathCase) path = path.toLowerCase();

  const prepend = '/dxpweb';
  const prependReplacement = env('DC_PREPENDREPLACE');
  if (prependReplacement && path.startsWith(prepend)) {
    path = path.replace(prepend, prependReplacement);
  }

  const url = new URL(path, OpenAPI.HOST);
  const queryOptions = options.query;
  if (queryOptions) {
    Object.keys(queryOptions).forEach(key => {
      if (queryOptions[key] === undefined || queryOptions[key] === null) {
        delete queryOptions[key];
      }
    });
  }
  url.search = new URLSearchParams(queryOptions).toString();
  return url;
}

function getFormData(params: Record<string, string | Blob>): FormData {
  const formData = new FormData();
  Object.keys(params).forEach(key => {
    const value = params[key];
    if (value !== undefined && value !== null) {
      formData.append(key, value);
    }
  });
  return formData;
}

export function getHeaders(options: ApiRequestOptions): Headers {
  const token = OpenAPI.TOKEN;
  const defaultHeaders = OpenAPI.HEADERS;

  const headers = new Headers({
    Accept: 'application/json',
    ...defaultHeaders,
    ...options.headers,
  });

  if (typeof token === 'string' && token !== '') {
    headers.append('Authorization', token);
  }

  if (options.body) {
    if (options.body instanceof Blob) {
      headers.append(HEADER_CONTENT_TYPE, options.body.type || 'application/octet-stream');
    } else if (typeof options.body === 'string') {
      headers.append(HEADER_CONTENT_TYPE, 'text/plain');
    } else {
      headers.append(HEADER_CONTENT_TYPE, 'application/json');
    }
  }
  return headers;
}

export function getRequestBody(options: ApiRequestOptions): BodyInit | undefined {
  if (options.formData) {
    return getFormData(options.formData);
  }
  if (options.body) {
    if (typeof options.body === 'string' || options.body instanceof Blob) {
      return options.body;
    }
    return JSON.stringify(options.body);
  }
  return undefined;
}

function getResponseType(response: Response): string | undefined {
  return response.headers.get(HEADER_CONTENT_TYPE)?.toLowerCase();
}

export function getResponseBody(response: Response): Promise<unknown> | null {
  try {
    const contentType = getResponseType(response);
    if (contentType) {
      if (contentType.startsWith('application/json')) {
        return response.json();
      }
      if (contentType.startsWith('application/pdf')) {
        return handleDownload(response, 'download.pdf');
      }
      if (contentType.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        return handleDownload(response, 'download.xlsx');
      }
      if (contentType.startsWith('application/zip')) {
        return handleDownload(response, 'download.zip');
      }

      return response.text();
    }
  } catch (error) {
    logger.error('07g4dZ', 'Unexpected error for parsing response body', error);
    // eslint-disable-next-line no-console
    console.error('Failure parsing body', error);
  }
  return null;
}

const extractFileName = (contentDispositionHeader: string | null) => {
  const split = (contentDispositionHeader || '').split(';');
  const match = split.find(part => part.includes('filename='));
  if (!match) return null;

  return match.split('=')[1].replace(/"/g, '');
};

async function handleDownload(response: Response, defaultFilename: string): Promise<Blob> {
  const filename = extractFileName(response.headers.get(HEADER_CONTENT_DISPOSITION)) || defaultFilename;
  const blob = await response.blob();
  handleBlobDownload(blob, filename);
  return blob;
}

export function catchErrors(options: ApiRequestOptions, result: ApiResult): void {
  const errors: Record<number, string> = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    ...options.errors,
  };

  const error = errors[result.status];
  if (error) {
    throw new ApiError(result, error);
  }

  if (!result.ok) {
    throw new ApiError(result, 'Generic Error - no errors found in body of response');
  }
}

type OnErrorRetryFn<Data> = SWRConfiguration<Data, ApiError>['onErrorRetry'];
/**
 * A helper function to create an onErrorRetry function to inject into useSWR config
 * @param statusCodes the status codes we should not retry on
 * @returns the onErrorRetry function to inject in config
 */
function doNotRetryOnErrors<Data>(...statusCodes: (number[] | number)[]): OnErrorRetryFn<Data> {
  const codes = new Set(statusCodes.flatMap(x => x));
  const onErrorRetry: OnErrorRetryFn<Data> = (error, key, config, revalidate, opts) => {
    // Skip retry for any status code in ...`statusCode
    if (codes.has(error.status)) return;

    if (!config.isVisible()) {
      // if it's hidden, stop
      // it will auto revalidate when focus
      return;
    }
    // Stop retrying if we've max our on maximum number of retries
    if (typeof config.errorRetryCount === 'number' && opts.retryCount > config.errorRetryCount) {
      return;
    }
    // exponential backoff
    const count = Math.min(opts.retryCount, 8);
    const timeout = ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval;
    setTimeout(revalidate, timeout, opts);
  };
  return onErrorRetry;
}

type InjectedParams = {
  injected: DCParams;
  missing: string[];
};

export function useInjectParams(info: Pick<InjectionInfo, 'injectables'> = { injectables: ['label'] }): InjectedParams {
  const dcParams = useDC();
  return useMemo(
    () =>
      info.injectables.reduce(
        (acc, key) => {
          if (dcParams[key]) {
            Object.assign(acc.injected, { [key]: dcParams[key] });
          } else {
            acc.missing.push(key);
          }
          return acc;
        },
        { injected: {}, missing: [] } as InjectedParams,
      ),
    [dcParams, info],
  );
}

/**
 * This wrapper function is inserted in the hook generation step, where every API call that returns
 * an object with just the 'data' prop. If the API returns a more complicated object, we leave it as it.
 * Otherwise we can conclude the actual pudding is held within the object begin the 'data' props, so we
 * return that instead.
 * The TypeScript magic tries to fetch the type information from the inner object, so we can continue
 * to safely use the return value.
 *
 * @param dcServiceCall The fetch call generated by the DC open api generator script
 * @returns The wrapper function postprocessing the successful results, collapsing 'data' prop
 */
export function collapseDataFromCall<
  T extends (params: any) => Promise<{ data?: any }>,
  P = T extends (params: infer P) => any ? P : never,
  R = T extends (params: any) => Promise<{ data?: infer D }> ? D : never,
>(dcServiceCall: T): (props: P) => Promise<R | undefined> {
  return props => dcServiceCall(props).then(result => result?.data);
}

type GetProps<T> = T extends (params: infer P) => any ? P : never;
type GetPropsFrom<T, Target extends string | number | symbol> = T extends (params: infer P) => any
  ? Target extends keyof P
    ? Required<P>[Target]
    : never
  : never;
export function collapseParams<
  T extends (params: any) => any,
  Target extends keyof GetProps<T>,
  PropertyName extends keyof GetPropsFrom<T, Target>,
  TargetObject extends Record<string, any> = GetProps<T>[Target],
  RestObject extends Record<string, any> = Omit<GetProps<T>, PropertyName | Target>,
>(
  dcServiceCall: T,
  targetProp: Target,
  ...hoistedProps: PropertyName[]
): (props: RestObject & TargetObject) => ReturnType<T> {
  function isInArray<T>(arr: T[], el: any): el is T {
    return arr.includes(el);
  }
  return props => {
    const newProps = Object.entries(props).reduce(
      (acc, [k, v]) => {
        if (isInArray(hoistedProps, k)) {
          acc[targetProp as string][k] = v;
        } else {
          acc[k] = v;
        }
        return acc;
      },
      { [targetProp]: {} as TargetObject } as GetProps<T>,
    );
    return dcServiceCall(newProps);
  };
}

export function createDCHook<T extends DCServiceCall, I extends InjectionInfo>(
  cacheKey: string,
  dcServiceCall: T,
  injectionInfo?: I,
  cacheControl?: {
    maxAge: number;
  },
): UseDCHook<T, I> {
  return function useRequest(params, options) {
    let shouldFetch = params !== null; // Pass `null` to prevent fetches (until set to another value)
    const { isAuthenticated } = useDC();
    const { injected, missing } = useInjectParams(injectionInfo);
    const hasMissingInjectables = injectionInfo?.injectables.length && missing.length > 0;

    // Cancel/silence the request for private DC endpoints when not authenticated. We are using the `customerId`
    // injectable here, since `path` is not available (private endpoints do not contain `/dxpweb/public/`).
    if (missing.includes('customerId') && !isAuthenticated) {
      shouldFetch = false;
    }

    const memoizedParams = useDeepCompareMemo(
      () => (hasMissingInjectables ? {} : { ...params, ...injected }),
      [params, injected],
    );

    const uniqueCacheKey = [cacheKey, ...Object.entries(memoizedParams).map(arr => `${arr[0]}=${arr[1]}`)];

    const doNotRetryOnStatusCodes = options?.doNotRetryOnStatusCodes ?? [401, 403, 404];
    const shouldRetryOnError = !hasMissingInjectables;
    const onErrorRetry = doNotRetryOnErrors(...doNotRetryOnStatusCodes);
    const onError: SWRConfiguration['onError'] = (error, key) => {
      logger.error('2LXhYA', 'DC fetch error', { error, key, uniqueCacheKey });
    };

    const middleware = (cacheControl?.maxAge && [revalidateIfCacheIsExpired(cacheControl.maxAge)]) || [];

    const config: SWRConfiguration = {
      onErrorRetry,
      onError,
      shouldRetryOnError,
      ...options,
      use: middleware,
    };

    const fetcher = () => {
      if (hasMissingInjectables) throw new Error(`Can't resolve injectables (${missing.join(', ')}) for ${cacheKey}`);
      return dcServiceCall(memoizedParams);
    };

    // See https://swr.vercel.app/docs/options for more details
    return useSWR<UnwrapReturnType<T>, ApiError>(shouldFetch ? uniqueCacheKey : null, fetcher, config);
  };
}

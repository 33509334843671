import { CountryCode, Language, Locale } from '@common/application';

export const businessUnit = 'be';
export const countryCode = 'BE';
export const label = 'eneco';
export const name = 'be-eneco-insights';
export const uuid = ''; // todo deifne
export const otherBasePaths = [];
export const i18n = {
  language: 'nl' as unknown as Language,
  languages: ['nl' as unknown as Language],
  locale: 'nl-BE' as unknown as Locale,
  locales: ['nl-BE' as unknown as Locale],
  countryCode: 'BE' as unknown as CountryCode,
};

import { LoadingSpinner, Stack, Stretch, Text } from '@sparky';

const Loader = ({ text }: { text?: string }) => (
  <Stretch>
    <Stack alignY="center" alignX="center">
      <LoadingSpinner isLoading={true} />
      {text && <Text weight="bold">{text}</Text>}
    </Stack>
  </Stretch>
);

export default Loader;

import { FC, useEffect } from 'react';

import { I18nProvider, useTranslation } from '@i18n';
import { Box, Button, NotificationBox, Stack, Stretch } from '@sparky';
import { useTracking } from '@tracking';

const ErrorBoundaryPageWrapper: FC = () => {
  return (
    <I18nProvider dictionary={locale => import(`./content/${locale}.json`)}>
      <ErrorBoundaryPage />
    </I18nProvider>
  );
};

const ErrorBoundaryPage: FC = () => {
  const { t } = useTranslation();
  const { trackError } = useTracking();

  useEffect(() => {
    trackError({ message: 'Caught error in error boundary' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stretch>
      <Stack alignY="justify">
        <Box padding="6">
          <NotificationBox
            isAlert
            title={t('errorBoundary.notification.title')}
            text={t('errorBoundary.notification.text')}
            variant="error"
          />
        </Box>
        <Box padding="6">
          <Stretch>
            <Stack alignY="justify" gap="4">
              <Button
                onClick={() => {
                  window.location.assign('/');
                }}>
                {t('errorBoundary.retryButton')}
              </Button>
            </Stack>
          </Stretch>
        </Box>
      </Stack>
    </Stretch>
  );
};

export default ErrorBoundaryPageWrapper;

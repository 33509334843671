import { FC } from 'react';

import NativeAppWrapper from '@custom-components/native/NativeAppWrapper';
import { Head } from '@dxp-next';

type Props = {
  children: React.ReactNode;
};

const NativeApp: FC<Props> = ({ children }) => {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="user-scalable=no, viewport-fit=cover, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi"
        />
      </Head>
      <NativeAppWrapper>{children}</NativeAppWrapper>
    </>
  );
};

export default NativeApp;

import { isPlatform } from '@ionic/react';
import { AuthService as AuthServiceAppAuth } from 'ionic-appauth';
import { AuthActions, IAuthAction } from 'ionic-appauth';
import { CapacitorStorage } from 'ionic-appauth/lib/capacitor';

import env from '@common/env';
import logger from '@common/log';

import authBrowser from './AuthBrowser';
import { AuthRequestor } from './AuthRequestor';
import { refreshToken } from './service-helpers';

let authService: null | AuthService = null;

export class AuthService extends AuthServiceAppAuth {
  async initWithRefresh() {
    await refreshToken(this.configuration, this.authConfig);
    this.init();
    this.setupLogging();
  }

  setupLogging() {
    this.events$.subscribe((event: IAuthAction) => {
      switch (event.action) {
        case AuthActions.LoadUserInfoFailed:
          logger.error('4t34GD', `Login error: ${AuthActions.LoadUserInfoFailed}`);
          break;
        case AuthActions.RevokeTokensFailed:
          logger.error('vTz7jv', `Login error: ${AuthActions.RevokeTokensFailed}`);
          break;
        case AuthActions.RefreshFailed:
          logger.error('dVWS4L', `Login error: ${AuthActions.RefreshFailed}`);
          break;
        case AuthActions.LoadTokenFromStorageFailed:
          logger.error('RJ0bOO', `Login error: ${AuthActions.LoadTokenFromStorageFailed}`);
          break;
      }
    });
  }
}

export function getAuthService() {
  if (authService) {
    return authService;
  }
  const authRequester = new AuthRequestor();
  authService = new AuthService(authBrowser, new CapacitorStorage(), authRequester);

  const client_id = env('AUTH_CLIENT_ID');
  const server_host = env('AUTH_SERVER_HOST');

  const redirect_url = isPlatform('capacitor')
    ? `${env('CAPACITOR_LOCAL_SCHEMA')}://login/callback`
    : window.location.origin + '/login/callback';

  const end_session_redirect_url = isPlatform('capacitor')
    ? `${env('CAPACITOR_LOCAL_SCHEMA')}://logout/callback`
    : window.location.origin + '/logout/callback';

  const scopes = env('AUTH_SCOPES');

  authService.authConfig = {
    client_id,
    server_host,
    redirect_url,
    end_session_redirect_url,
    scopes,
    pkce: true,
  };

  authService.initWithRefresh();

  return authService;
}
